@use "sass:math";

.#{$prefix}main-nav {
  $module: &;
  $mobile-width: 280px;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  @include get-z-index('navigation');
  @each $bp, $gap in map-get($grid, gutter) {
    @include bp-up($bp) {
      padding-left: $gap;
      margin-left: -$gap;
    }
  }
  &::before {
    content: '';
    left: 0;
    top: 0;
    height: 100vh;
    background: #fff;
    position: fixed;
    display: block;
    visibility: hidden;
    width: $mobile-width;
    transform: translateX(-100%);
    transition: transform map-get($transition-duration, default) ease-in-out, visibility 0s map-get($transition-duration, default);
  }
  &--opened {
    &::before {
      visibility: visible;
      transform: translateX(0);
      transition: transform map-get($transition-duration, default) ease-in-out;
    }
  }
  &__icon {
    position: relative;
    @include bp-up(md) {
      display: none;
    }
  }
  &__icon-bar {
    width: 18px;
    height: 2px;
    background: currentColor;
    position: absolute;
    display: block;
    transition: transform map-get($transition-duration, default);
    z-index: 3;
    &:nth-of-type(1) {
      left: 9px;
      top: 13px;
      transform-origin: math.div(17, 18)*100% 50%;
    }
    &:nth-of-type(2) {
      left: 11px;
      top: 19px;
      transform-origin: 50% 50%;
    }
    &:nth-of-type(3) {
      left: 13px;
      top: 25px;
      transform-origin: math.div(100%, 18) 50%;
    }
    #{$module}--opened & {
      &:nth-of-type(1) {
        transform: rotate(-45deg) scaleX(1.03);
      }
      &:nth-of-type(2) {
        transform: rotate(45deg);
      }
      &:nth-of-type(3) {
        transform: rotate(-45deg) scaleX(0);
        opacity: 0;
      }
    }
  }
  &__item {
    flex-shrink: 0;
    @include bp-down(sm) {
      visibility: hidden;
      opacity: 0;
      transition: opacity map-get($transition-duration, default) 0s, visibility 0s map-get($transition-duration, default);
      #{$module}--opened & {
        opacity: 1;
        visibility: visible;
        transition: opacity map-get($transition-duration, default) map-get($transition-duration, default);
      }
    }
  }
  &__link {
    $link-class: &;
    display: flex;
    line-height: 88px;
    text-transform: uppercase;
    color: map-get($colour, navy);
    @extend %reset-anchor;
    &:hover, &:focus {
      color: map-get($colour, blue);
    }
    &--active:any-link {
      color: map-get($colour, navy);
    }
    @include bp-down(sm) {
      line-height: 55px;
      border: 0;
      border-bottom: 1px solid #dedede;
    }
  }
  &__link-title {
    font-family: map-get($font-family, montserrat);
    font-weight: 500;
    font-size: rem(15);
    margin: 0;
    position: relative;
    @include bp-up(md) {
      margin: 0 16px;
    }
    &::after {
      $line: 2px;
      content: '';
      height: $line;
      background: currentColor;
      position: absolute;
      bottom: -1px;
      left: 0;
      border-radius: $line*0.5;
      transition: transform map-get($transition-duration, default) ease-in-out;
      width: 100%;
      transform: scaleX(0);
      transform-origin: 0% 50%;
      @include bp-up(md) {
        bottom: 8px;
      }
    }
    #{$module}__link--active &,
    #{$module}__link:hover &,
    #{$module}__link:focus & {
      &::after {
        transform: scale(1);
      }
    }
  }
  &__list {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    @extend %reset-list;
    @each $bp, $gap in map-get($grid, gutter) {
      @if index(('xs', 'sm'), $bp) != null {
        @include bp-only($bp) {
          padding: 0 2*$gap;
        }
      }
    }
    @include bp-down(sm) {
      align-items: stretch;
      width: $mobile-width;
      position: absolute;
      flex-direction: column;
      top: 100%;
      left: 0;
      visibility: hidden;
      transform: translateX(-100%);
      transition: transform map-get($transition-duration, default) ease-in-out, visibility 0s map-get($transition-duration, default);
      #{$module}--opened & {
        transform: translateX(0);
        visibility: visible;
        transition: transform map-get($transition-duration, default) ease-in-out;
      }
    }
  }
}