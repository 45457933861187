.#{$prefix}single-service {
  @include of-type(main) {
    @extend %grid, %container;
    grid-auto-rows: min-content;
  }
  &__app {
    grid-column: 2 / span 10;
    margin-bottom: 32px;
  }
  &__header {
    grid-column: 2 / span 10;
    margin-bottom: 24px;
  }
}