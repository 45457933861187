@use "sass:math";

.#{$prefix}par {
  margin: 24px 0;
  font-family: map-get($font-family, rubik);
  line-height: math.div(24, 16);
  color: map-get($colour, not-black);
  &:first-child, .#{$prefix}media + & {
    margin-top: 0;
  }
}