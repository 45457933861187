@use "sass:math";

.#{$prefix}icon-link, .#{$prefix}link-button {
  $size: 40px;
  @extend %reset-anchor;
  padding: 8px 16px;
  color: map-get($colour, navy);
  transition: background-color map-get($transition-duration, default);
  border-radius: 4px;
  display: block;
  line-height: math.div(24, 16);
  font-weight: 500;
  font-family: map-get($font-family, rubik);
  float: left;
  clear: both;
  &:hover, &:focus {
    background-color: map-get($colour, light-blue);
  }
  &:active {
    background-color: mix(map-get($colour, indigo), #fff, 40%);
  }
  .#{$prefix}icon-link &__icon, &--external.#{$prefix}link-button::before, &[target='_blank'].#{$prefix}link-button::before {
    display: inline-block;
    width: $size;
    height: $size;
    vertical-align: middle;
    margin: -8px 8px -8px -16px;
  }
  &--external.#{$prefix}link-button, &[target='_blank'].#{$prefix}link-button {
    display: inline-flex;
    align-items: center;
    &::before {
      content: url('./img/icon/external.svg');
      flex: 0 0 $size;
    }
  }
}