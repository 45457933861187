.#{$prefix}heading {
  &--h2 {
    @extend %default-h2;
    margin: 72px 0 32px;
    &:first-child {
      margin-top: 0;
    }
  }
  &--h3 {
    @extend %default-h3;
    margin: 72px 0 1em;
    &:first-child {
      margin-top: 0;
    }
  }
  &--h4 {
    @extend %default-h4;
    margin: 40px 0 16px;
    &:first-child {
      margin-top: 0;
    }
  }
  &--h5 {
    @extend %default-h5;
    margin: 40px 0 1em;
    &:first-child {
      margin-top: 0;
    }
  }
}