.#{$prefix}breadcrumb {
  &__item {
    display: inline;
    color: #777;
    &:first-child {
      &::before {
        display: none;
      }
    }
    &::before {
      content: '>';
      display: inline-block;
      transform: scaleX(.8);
      margin: 0 8px;
    }
  }
  &__link {
    @extend %reset-anchor, %default-a;
    font-family: map-get($font-family, rubik);
    font-size: rem(14);
    text-decoration: underline;
  }
  &__list {
    @extend %reset-list;
    padding: 20px 0;
  }
}