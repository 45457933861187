.#{$prefix}cols {
  $module: &;
  @extend %grid;
  > * {
    grid-column-end: span 12;
    @at-root #{$module}--double#{&} {
      @include bp-up(md) {
        grid-column-end: span 6;
      }
    }
    @at-root #{$module}--triple#{&} {
      @each $bp, $span in (md: 6, lg: 4) {
        @include bp-up($bp) {
          grid-column-end: span $span;
        }
      }
    }
    @at-root #{$module}--quadruple#{&} {
      @each $bp, $span in (sm: 6, md: 4, lg: 3) {
        @include bp-up($bp) {
          grid-column-end: span $span;
        }
      }
    }
  }
  &__img {
    margin-top: 24px;
    max-width: 100%;
  }
}