$bg: '.#{$prefix}bg';
$container: '.#{$prefix}container';
$container-spacing: 40px;
$content: '.#{$prefix}content';
#{$bg} {
  overflow: hidden;
  &--blue {
    background-color: map-get($colour, light-blue);
  }
  &--grey {
    background-color: #f8f8f8;
  }
  &--indigo {
    background-color: mix(map-get($colour, indigo), #fff, 6%);
  }
  &--red {
    background-color: map-get($colour, red-error);
  }
  &--orange {
    background-color: map-get($colour, orange);
  }
  &--green {
    background-color: map-get($colour, green);
  }
  &--white {
    background-color: map-get($colour, #fff);
  }
}
#{$container} {
  @extend %container, %grid;
  grid-row-gap: $container-spacing;
  @include of-type(header) {
    grid-row-gap: 16px;
  }
  &:not(header) {
    padding-top: 56px;
    padding-bottom: 72px;
  }
  &.pb-0 {
    padding-bottom: 0;
  }

  @at-root div#{$container}:not(#{$bg}) + div#{$container}:not(#{$bg}) {
    padding-top: 0;
  }
}
#{$content} {
  #{$container} > & {
    grid-column: 1 / span 12;
    @include bp-up(md) {
      &--main {
        grid-column-end: span 10;
      }
    }
    @include bp-up(lg) {
      &--main {
        grid-column-end: span 8;
      }
      &--side {
        grid-column: 10 / span 3;
      }
    }
  }
  @for $blocks from 2 through 11 {
    &[data-row-span='#{$blocks}'] {
      grid-row: 1 / span $blocks;
      @include bp-down(md) {
        grid-row: ($blocks + 1) / span 1;
      }
    }
  }
  &--border {
    border-top: 2px solid map-get($colour, indigo);
    padding-top: 36px;
  }
}