@use "sass:math";

.#{$prefix}page-header {
  transition: box-shadow map-get($transition-duration, movement);
  .#{$prefix}body--message & {
    &-closed {
      box-shadow: 0 -32px 32px -32px rgba(#000, .5);
    }
  }
  &__container {
    @extend %grid, %container;
  }
  &__homelink {
    margin: auto 0;
  }
  &__logo {
    display: block;
    @each $bp, $height in (
      xs: 18px,
      sm: 22px,
      lg: 24px
    ) {
      @include bp-up($bp) {
        height: $height;
        width: $height*math.div(192, 24);
      }
    }
  }
  &__nav {
    @each $bp, $column in (
      xs: 1 / span 3,
      sm: 1 / span 1,
      md: 4 / span 4
    ) {
      @include bp-up($bp) {
        grid-column: $column;
      }
    }
  }
  &__quick-links {
    @extend %reset-list;
    display: flex;
    align-items: center;
  }
  &__quick-link {
    flex-shrink: 0;
    font-size: rem(14);
    margin: 7px 0 7px 12px;
    &:first {
      margin-left: 0;
    }
  }
  &__side {
    font-weight: 400;
    font-size: rem(14);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @each $bp, $column in (
      xs: 10 / span 3,
      sm: 6 / span 7,
      md: 8 / span 5
    ) {
      @include bp-up($bp) {
        grid-column: $column;
      }
    }
  }
  &__title {
    display: flex;
    align-items: center;
    grid-column: 4 / span 6;
    justify-content: center;
    @include bp-up(sm) {
      grid-column: 2 / span 4;
      justify-content: flex-start;
    }
    @include bp-up(md) {
      grid-column: 1 / span 3;
    }
  }
}