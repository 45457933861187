%container {
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  $container-max: map-get($grid, max-container-width);
  $gutter: map-get($grid, gutter);
  $bp-list: map-keys($gutter);
  @each $bp, $gap in $gutter {
    $i: index($bp-list, $bp);
    @while not map-has-key($container-max, nth($bp-list, $i)) and $i < length($bp-list) {
      $i: $i + 1;
    }
    @include bp-only($bp) {
      max-width: map-get($container-max, nth($bp-list, $i));
      @if not map-has-key($container-max, $bp) {
        padding: 0 $gap;
      }
    }
  }
}
@mixin _grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-flow: row dense;
  $gutter: map-get($grid, gutter);
  @each $bp, $gap in $gutter {
    @include bp-only($bp) {
      grid-column-gap: $gap;
    }
  }
}
%grid {
  @include _grid;
}