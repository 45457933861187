@mixin bp-between($start, $stop) {
  $breakpoints: map-get($grid, breakpoint);
  @if map-has-key($breakpoints, $start) and map-has-key($breakpoints, $stop) {
    $min: ''; $and: ''; $max: '';
    $keys: map-keys($breakpoints);
    @if index($keys, $start) > 1 {
      $min: (min-width: map-get($breakpoints, $start));
    }
    @if index($keys, $stop) < length($keys) {
      $max: (max-width: map-get($breakpoints, nth($keys, index($keys, $stop) + 1)) - 1px);
    }
    @if $min != '' and $max != '' {
      $and: ' and ';
    }
    @if $min != '' or $max != '' {
      @media #{$min}#{$and}#{$max} {
        @content;
      }
    } @else {
      @content;
    }
  } @else {
    @each $bp in ($start, $stop) {
      @if not map-has-key($breakpoints, $bp)  {
        @debug 'Breakpoint "#{$bp}" not found.';
      }
    }
  }
}
@mixin bp-down($bp) {
  $breakpoints: map-get($grid, breakpoint);
  @if map-has-key($breakpoints, $bp) {
    $keys: map-keys($breakpoints);
    @if index($keys, $bp) < length($keys) {
      @media (max-width: (map-get($breakpoints, nth($keys, index($keys, $bp) + 1)) - 1px)) {
        @content;
      }
    } @else {
      @content;
    }
  } @else {
    @debug 'Breakpoint "#{$bp}" not found.';
  }
}
@mixin bp-only($bp) {
  $breakpoints: map-get($grid, breakpoint);
  @if map-has-key($breakpoints, $bp) {
    $min: ''; $and: ''; $max: '';
    $keys: map-keys($breakpoints);
    @if index($keys, $bp) > 1 {
      $min: "(min-width: #{map-get($breakpoints, $bp)})";
    }
    @if index($keys, $bp) < length($keys) {
      $max: "(max-width: #{map-get($breakpoints, nth($keys, index($keys, $bp) + 1)) - 1px})";
    }
    @if $min != '' and $max != '' {
      $and: ' and ';
    }
    @if $min != '' or $max != '' {
      @media #{$min}#{$and}#{$max} {
        @content;
      }
    } @else {
      @content;
    }
  } @else {
    @debug 'Breakpoint "#{$bp}" not found.';
  }
}
@mixin bp-up($bp) {
  $breakpoints: map-get($grid, breakpoint);
  @if map-has-key($breakpoints, $bp) {
    @if index(map-keys($breakpoints), $bp) > 1 {
      $bp-val: map-get($breakpoints, $bp);
      @media (min-width: $bp-val) {
        @content;
      }
    } @else {
      @content;
    }
  } @else {
    @debug 'Breakpoint "#{$bp}" not found.';
  }
}