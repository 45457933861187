@mixin pseudoArrowDirection($direction, $clockwise: true, $pseudo: 'before') {
  $directions: (right: 45deg, bottom: 135deg, left: 225deg, top: 315deg);
  $rotate: map-get($directions, $direction);
  @if not $clockwise {
    $rotate: $rotate - 360deg;
  }
  &::#{$pseudo} {
    transform: rotate(#{$rotate}) translate(-25%, 25%);
  }
}
@mixin pseudoArrow($colour: currentColor, $transition: false, $pseudo: 'before', $size: 10px) {
  &::#{$pseudo} {
    $border: 2px;
    content: '';
    display: inline-block;
    border-width: $border $border .01px .01px;
    border-color: $colour $colour transparent transparent;
    border-style: solid;
    box-sizing: border-box;
    width: $size;
    height: $size;
    flex: 0 0 $size;
    margin-left: $size * .5 * (sqrt(2) - 1);
    margin-right: 16px;
    vertical-align: middle;
    @if $transition {
      transition: margin-left map-get($transition-duration, default), transform map-get($transition-duration, default);
    }
    @content;
  }
}