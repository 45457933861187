@use "sass:math";

%default {
  @mixin default-heading {
    font-family: map-get($font-family, montserrat);
    font-weight: 500;
    margin: 0;
  }
  @mixin default-list {
    @extend .#{$prefix}list;
    li {
      @extend .#{$prefix}list__item;
    }
  }
  &-a {
    @extend .#{$prefix}anchor;
  }
  &-h2 {
    @include default-heading;
    font-size: rem(28);
  }
  &-h3 {
    @include default-heading;
    font-size: rem(24);
  }
  &-h4 {
    $font-size: 20;
    @include default-heading;
    font-size: rem($font-size);
    font-weight: 400;
    line-height: (24*0.05);
  }
  &-h5 {
    $font-size: 16;
    @include default-heading;
    font-style: italic;
    font-size: rem($font-size);
    font-weight: 400;
    line-height: math.div(20, $font-size);
  }
  &-li {
    font-family: map-get($font-family, rubik);
    @extend .#{$prefix}list__item;
  }
  &-ol {
    @include default-list;
    @extend .#{$prefix}list--ol;
  }
  &-p {
    @extend .#{$prefix}par;
  }
  &-strong {
    @extend .#{$prefix}strong;
  }
  &-ul {
    @include default-list;
    @extend .#{$prefix}list--ul;
    ul {
      @extend .#{$prefix}list--sub;
    }
    ul, ol {
      margin-top: 8px;
    }
  }
  &-table {
    @extend .#{$prefix}table;
    @each $tag in ('tbody', 'td', 'th', 'thead', 'tr') {
      #{$tag} {
        @extend .#{$prefix}table__#{$tag};
      }
    }
  }
}