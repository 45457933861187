.#{$prefix}logo {
  $module: &;
  fill: map-get($colour, navy);
  &__icon {
    @each $c in (blue, navy, oker) {
      &--#{"" + $c} {
        fill: map-get($colour, $c);
      }
    }
  }
}