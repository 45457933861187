@use "sass:math";

$lamel-height: (xs: 6px, sm: 7px, md: 8px);
.#{$prefix}lamel {
  background-repeat: repeat-x;
  background-image: url('./img/lamel.svg');
  display: block;
  @include get-z-index('lamel');
  @each $bp, $size in $lamel-height {
    @include bp-up($bp) {
      height: $size;
      background-position: calc(50% + #{.75*$size}) 0;
      background-size: math.div($size, 8px)*540px $size;
    }
  }
}