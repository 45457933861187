@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes popUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-.5px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}
@keyframes tableFlip {
  0% {
    transform: scaleY(0) rotateX(-90deg);
    opacity: 0;
    border-bottom-color: #fff;
  }
  100% {
    transform: scaleY(1) rotateX(0);
    opacity: 1;
    border-bottom-color: #d4d4d4;
  }
}