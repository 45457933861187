$back-to-front: (
  'lamel',
  'media-content',
  'media-thumb',
  'media-thumb-overlay',
  'stickynav',
  'user-nav',
  'modal',
  'lock-overlay',
  'navigation',
  'media-overlay',
  'media-enlarge'
);
@mixin get-z-index($level, $list: $back-to-front) {
  @if index($list, $level) == null {
    @debug $level+' could not be found.';
  } @else {
    z-index: index($list, $level) + 1;
  }
}