.#{$prefix}button {
    @extend %reset-anchor;
    appearance: none;
    border: none;
    cursor: pointer;
    padding: 0.85em 1.28em;
    border-radius: 4px;
    transition: background-color map-get($transition-duration, default);
    display: inline-block;
    font-family: map-get($font-family, rubik);
    &--normal {
        color: map-get($colour, navy);
        background-color: #fff;
        &:hover,
        &:focus {
            background-color: map-get($colour, light-blue);
        }
        &:active {
            background-color: mix(map-get($colour, indigo), #fff, 40%);
        }
    }
    &--primary {
        color: #fff;
        background-color: map-get($colour, analyse);
        &:hover,
        &:focus {
            background-color: map-get($colour, oker);
        }
    }
    &--secondary {
        color: map-get($colour, navy);
        background-color: map-get($colour, selector-blue);
        &:hover,
        &:focus {
            color: #fff;
            background-color: map-get($colour, blue);
        }
        &:active {
            color: #fff;
            background-color: map-get($colour, navy);
        }
    }
    &--secondary2 {
        color: #fff;
        background-color: map-get($colour, blue);
        svg {
            fill: #fff;
        }
        &:hover,
        &:focus {
            background-color: map-get($colour, navy);
        }
        &:active {
            background-color: map-get($colour, blue);
        }
    }
    &--outline--secondary2 {
        background-color: #fff;
        border: 1px solid map-get($colour, blue);
        color: map-get($colour, blue);
        svg {
            fill: map-get($colour, blue);
        }
        &:hover,
        &:focus {
            color: #fff;
            background-color: map-get($colour, blue);
            svg {
                fill: #fff;
            }
        }
        &:active {
            color: map-get($colour, blue);
            background-color: #fff;
            svg {
                fill: map-get($colour, blue);
            }
        }
    }
}