.#{$prefix}body {
  $module: &;
  height: 100%;
  min-height: 100%;
  min-width: nth(map-values(map-get($grid, breakpoint)), 1);
  display: grid;
  grid-template-rows: auto minmax(max-content, 1fr) auto;
  grid-template-columns: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  &:not(#{$module}--tabbed) * {
    &:focus, &:active {
      outline: 0;
    }
  }
  &::before {
    content: '';
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color map-get($transition-duration, default) 0s, visibility 0s map-get($transition-duration, default);
  }
  &--locked {
    overflow: hidden;
    height: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    &::before {
      background-color: rgba(0, 0, 0, .2);
      visibility: visible;
      transition: background-color map-get($transition-duration, default), visibility 0s 0s;
      @include get-z-index('lock-overlay');
    }
  }
  &--media {
    &::before {
      background-color: #fff;
      visibility: visible;
      transition: background-color map-get($transition-duration, default), visibility 0s 0s;
      @include get-z-index('media-overlay');
      cursor: zoom-out;
    }
  }
  &--message {
    grid-template-rows: min-content minmax(max-content, 1fr) auto;
  }
}