@use "sass:math";

.#{$prefix}banner {
  $module: &;
  $radius: 8px;
  background: map-get($colour, blue);
  &:first-child {
    margin-top: 24px;
  }
  @include bp-up(md) {
    border-radius: $radius;
  }
  @each $bp, $gap in map-get($grid, gutter) {
    $max-container: map-get($grid, max-container-width);
    $outside: $gap;
    @if map-has-key($max-container, $bp) {
      $breakpoint: map-get($grid, breakpoint);
      $outside: .5 * (map-get($breakpoint, $bp) - map-get($max-container, $bp));
    }
    @include bp-up($bp) {
      padding-left: $outside;
      padding-right: $outside;
      margin-left: -$outside;
      margin-right: -$outside;
    }
  }
  &__contain {
    @extend %grid, %container;
    @include bp-up(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-top: 32px;
    padding-bottom: 32px;
    color: #fff;
    @include bp-up(sm) {
      padding-top: 48px;
      padding-bottom: 48px;
    }
    @include bp-up(md) {
      padding-top: 64px;
      padding-bottom: 64px;
      #{$module}--medium & {
        min-height: 168px;
      }
      #{$module}--extended &, #{$module}--search & {
        min-height: 224px;
      }
      #{$module}--extended.small & {
        min-height: 156px;
      }
    }
    .#{$prefix}rich-text p:not([class*='#{$prefix}']) {
      $font-size: 18;
      color: inherit;
      font-size: rem($font-size);
      line-height: math.div(28, $font-size);
    }
    > :last-child {
      margin-bottom: 0;
    }
    #{$module}--basic & {
      grid-column: 1 / span 12;
      @include bp-up(sm) {
        grid-column: 1 / span 8;
      }
    }
    #{$module}--medium & {
      @each $bp, $column in (
        xs: 1 / span 12,
        sm: 1 / span 10,
        md: 1 / span 7,
        lg: 1 / span 8
      ) {
        @include bp-up($bp) {
          grid-column: $column;
        }
      }
    }
    #{$module}--extended & {
      @each $bp, $column in (
        xs: 1 / span 12,
        sm: 1 / span 10,
        md: 1 / span 6,
        lg: 1 / span 3
      ) {
        @include bp-up($bp) {
          grid-column: $column;
        }
      }
    }
    #{$module}--search & {
      grid-column: 1 / span 12;
      @include bp-up(md) {
        grid-column: 1 / span 7;
      }
    }
  }
  &__circle {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    max-height: 100%;
    margin: 0;
    display: block;
    fill: map-get($colour, blue);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 1px;
    @include bp-up(md) {
      border-radius: 0 8px 8px 0;
    }
    @each $bp, $gap in map-get($grid, gutter) {
      $max-container: map-get($grid, max-container-width);
      $outside: $gap;
      @if map-has-key($max-container, $bp) {
        $breakpoint: map-get($grid, breakpoint);
        $outside: .5 * (map-get($breakpoint, $bp) - map-get($max-container, $bp));
      }
      @include bp-up($bp) {
        width: calc(100% + #{$outside});
        margin-right: -$outside;
      }
    }
  }
  &__image {
    position: relative;
    #{$module}--medium & {
      @include bp-down(sm) {
        display: none;
      }
      @include bp-up(md) {
        grid-column: 9 / span 4;
      }
      @include bp-up(lg) {
        grid-column: 10 / span 3;
      }
    }
    #{$module}--extended & {
      @include bp-down(sm) {
        display: none;
      }
      grid-column: 7 / span 6;
      @include bp-up(lg) {
        grid-column: 5 / span 8;
      }
    }
    #{$module}--search & {
      grid-column: 8 / span 5;
      @include bp-down(sm) {
        display: none;
      }
    }
  }
  &__link {
    border-radius: 8px;
    color: inherit;
    font-weight: 400;
    align-self: flex-start;
    &:not(:first-child) {
      margin-top: 32px;
    }
    &:hover, &:focus {
      background-color: rgba(map-get($colour, navy), .32);
    }
  }
  &__links {
    &:not(:first-child) {
      margin-top: 32px;
    }
    @extend %reset-list;
  }
  &__link-item {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 18px;
    }
  }
  &__subtitle {
    $margin-bottom: 16px;
    font-family: map-get($font-family, rubik);
    font-weight: 500;
    margin: 0 0 $margin-bottom;
    order: -1;
    font-size: rem(18);
    font-weight: 400;
    #{$module}__title + &:last-child {
      margin-bottom: $margin-bottom;
    }
  }
  &__title {
    font-family: map-get($font-family, montserrat);
    font-weight: 500;
    margin: 0 0 24px;
    font-size: rem(24);
    @include bp-up(sm) {
      font-size: rem(28);
    }
    @include bp-up(md) {
      font-size: rem(32);
    }
    @include bp-up(lg) {
      font-size: rem(36);
    }
  }
}