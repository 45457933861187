@use 'sass:math';

.#{$prefix}user-nav {
    $module: &;
    $border-colour: #99c3ed;
    position: relative;
    font-family: map-get($font-family, rubik);

    @include bp-down(sm) {
        display: none;
    }

    @each $bp, $gap in map-get($grid, gutter) {
        @if index(('md', 'lg'), $bp) != null {
            @include bp-only($bp) {
                margin-left: $gap;
            }
        }
    }

    &__menu-btn {
        @include of-type(a) {
            @extend %reset-anchor;
        }

        @include of-type(button) {
            @extend %reset-button;
        }

        cursor: pointer;
        border-radius: 4px;
        transition: background-color map-get($transition-duration, default);

        #{$module} & {
            background-color: map-get($colour, bg-blue);
            border: 0;
            color: #000;
            margin: 0;
            text-align: left;
            border-radius: 0;
            padding: 8px 16px;
            text-transform: none;
            font-size: 14px;
        }

        &:first-of-type {
            margin-top: 16px;
        }

        @include of-type(a) {
            display: block;
        }

        &:hover,
        &:focus {
            background-color: map-get($colour, light-blue);
            color: map-get($colour, navy);
        }
    }

    &__button {
        @include of-type(a) {
            @extend %reset-anchor;
        }

        @include of-type(button) {
            @extend %reset-button;
        }

        cursor: pointer;
        border-radius: 4px;
        transition: background-color map-get($transition-duration, default);

        #{$module} & {
            background-color: map-get($colour, bg-blue);
            border: 1px solid mix(map-get($colour, indigo), #fff, 40%);
            color: map-get($colour, navy);
        }

        @include of-type(a) {
            display: block;
        }

        &:hover,
        &:focus {
            background-color: map-get($colour, light-blue);
        }
    }

    &__launch {
        text-align: left;
        align-items: center;
        display: inline-flex;

        #{$module}--opened & {
            background-color: map-get($colour, light-blue);
        }
    }

    &__detail {
        $detail: &;
        color: #777;
        display: inline;

        #{$module} & {
            line-height: math.div(22, 14);
        }

        &--head {
            color: #000;
            text-transform: uppercase;

            &#{$detail}--title {
                @extend %visually-hidden;
            }
        }

        &--title {
            &::after {
                content: ': ';
            }
        }

        &--value {
            @extend %line-break;
        }
    }

    &__details {
        @extend %reset-detaillist;
        padding: 16px;
        border-bottom: 1px solid $border-colour;
    }

    &__dots {
        height: 40px;
        width: 40px;
    }

    &__logout {
        @extend %reset-anchor;
        border: none;
        border-top: 1px solid $border-colour;
        padding: 16px 18px;
        margin-top: 16px;
        text-align: left;
        display: block;
        color: #000;
        text-transform: none;
        transition: background-color map-get($transition-duration, default);

        &:hover,
        &:focus {
            background-color: map-get($colour, light-blue);
            color: map-get($colour, navy);
        }
    }

    &__popup {
        @include get-z-index('user-nav');
        $radius: 2px;
        position: absolute;
        top: calc(100% - #{$radius});
        right: 0;
        width: 296px;
        background-color: #f5f9fc;
        border: 1px solid $border-colour;
        border-radius: $radius;
        visibility: hidden;
        opacity: 0;
        transform: scale(0.9);
        transform-origin: 100% 0%;
        transition: opacity map-get($transition-duration, default), transform map-get($transition-duration, default), visibility 0s map-get($transition-duration, default);

        #{$module}--opened & {
            visibility: visible;
            opacity: 1;
            transform: scale(1);
            transition: opacity map-get($transition-duration, default), transform map-get($transition-duration, default);
        }
    }

    &__profile {
        @extend %reset-anchor;
        margin: 16px;
        text-align: center;
        padding: 8px;
        font-size: rem(12);
        text-transform: uppercase;
    }

    &__user {
        margin: 12px;
        display: inline-flex;
        flex-direction: column;
        white-space: nowrap;

        &--name {
            color: map-get($colour, navy);
            margin-bottom: 4px;
            overflow: hidden;
            display: block;
        }

        &--id {
            color: #333;
            font-size: rem(12);
        }
    }
}
