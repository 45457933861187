%reset {
  &-anchor {
    color: inherit;
    text-decoration: inherit;
  }
  &-button {
    background: none;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    -webkit-appearance: button;
    user-select: none;
    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
  }
  &-blockquote {
    margin: 0;
    padding: 0;
  }
  &-detaillist {
    &, dt, dd {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
    }
  }
  &-details {
    display: block;
    > summary {
      list-style-type: none;
      &::-webkit-details-marker {
        display: none;
      }
    }
    &:not([open]) > summary ~ * {
      display: none;
    }
  }
  &-fieldset {
    border: 0;
    padding: 0.01em 0 0 0;
    margin: 0;
    min-width: 0;
    legend {
      padding: 0;
      display: table;
    }
    body:not(:-moz-handler-blocked) & {
      display: table-cell;
    }
  }
  &-figure {
    margin: 0;
    padding: 0;
  }
  &-heading {
    margin: 0;
    padding: 0;
    font-weight: 400;
  }
  &-input {
    font-family: inherit;
    color: inherit;
    border: none;
    padding: 0;
    margin: 0;
    background: none;
    &::-ms-clear {
      display: none;
    }
  }
  &-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &-select {
    appearance: none;
    position: relative;
    background: none;
    border: none;
    border-radius: 0;
    &::-ms-expand {
      display: none;
    }
  }
  &-table {
    border-collapse: collapse;
    border-spacing: 0;
    &-tags {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
    }
  }
  &-textarea {
    font-family: inherit;
    color: inherit;
    &[disabled] {
      -webkit-text-fill-color: currentColor;
    }
  }
  &-th {
    text-align: left;
  }
}