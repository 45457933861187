@mixin _anchor {
  font-family: map-get($font-family, rubik);
  color: map-get($colour, navy);
  &:hover, &:focus {
    color: map-get($colour, blue);
    text-decoration: underline;
  }
  &[href^='tel:'] {
    white-space: nowrap;
  }
}
.#{$prefix}anchor {
  @extend %reset-anchor;
  @include _anchor;
}