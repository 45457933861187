@use "sass:math";

@mixin _list {
  font-family: map-get($font-family, rubik);
  padding: 0;
  grid-row-gap: 8px;
  margin-bottom: 32px;
  &:last-child {
    margin-bottom: 0;
  }
}
@mixin _list--($type) {
  @if $type == 'arrow' {
    list-style-type: none;
  }
  @if $type == 'check' {
    list-style-type: none;
  }
  @if $type == 'ol' {
    margin-left: -16px;
    padding-left: 40px;
  }
  @if $type == 'square' {
    list-style-type: none;
    padding-left: 40px;
  }
  @if $type == 'sub' {
    list-style-type: none;
  }
  @if $type == 'ul' {
    list-style-type: none;
    padding-left: 40px;
  }
}
@mixin _list__item {
  position: relative;
  line-height: math.div(24, 16);
  padding: 0 16px;
  &::before {
    position: absolute;
  }
}
@mixin _list__item--($type) {
  @if $type == 'arrow' {
    padding-left: 56px;
    @include pseudoArrow(map-get($colour, navy)) {
      left: 16px;
      top: 5px;
    }
    @include pseudoArrowDirection(right);
  }
  @if $type == 'blue' {
    &::before {
      color: map-get($colour, indigo);
    }
  }
  @if $type == 'check' {
    padding-left: 56px;
    @include pseudoCheck(map-get($colour, oker)) {
      left: 16px;
      top: 6px;
    }
  }
  @if $type == 'ol' {
    padding-left: 32px;
  }
  @if $type == 'square' {
    &::before {
      content: '';
      $size: 4px;
      background: currentColor;
      height: $size;
      width: $size;
      vertical-align: baseline;
      left: -24px;
      top: .5em;
    }
  }
  @if $type == 'sub' {
    &::before {
      content: '\25CB';
      left: -24px;
    }
  }
  @if $type == 'ul' {
    &::before {
      content: '\25CF';
      left: -24px;
      color: #777;
    }
  }
}
@mixin _list__link {
  margin-bottom: 4px;
  color: map-get($colour, navy);
  &:focus, &:hover {
    color: map-get($colour, indigo);
    text-decoration: underline;
  }
}
.#{$prefix}list {
  $module: &;
  @include _list;
  @each $type in ('arrow', 'check', 'ol', 'square', 'sub', 'ul') {
    &--#{$type} {
      @include _list--($type);
    }
  }
  &--arrow, &--check {
    @extend %reset-list;
  }
  &__item {
    @include _list__item;
    @each $type in ('arrow', 'check', 'ol', 'square', 'sub', 'ul', 'blue') {
      #{$module}--#{$type} & {
        @include _list__item--($type);
      }
    }
  }
  &__link {
    @extend %reset-anchor;
    @include _list__link;
  }
}