@mixin pseudoCheck($colour: currentColor) {
  &::before {
    content: '';
    display: inline-block;
    width: 17px;
    height: 8px;
    border-width: .01px .01px 2px 2px;
    border-color: transparent transparent $colour $colour;
    border-style: solid;
    transform: rotate(-45deg);
    margin-right: 16px;
    box-sizing: border-box;
    vertical-align: top;
    transition: opacity map-get($transition-duration, default);
    @content;
  }
}