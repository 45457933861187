@use "sass:math";

.#{$prefix}table {
  $module: &;
  @extend %reset-table-tags, %reset-table;
  width: 100%;
  margin-bottom: 32px;
  %table-cell {
    line-height: math.div(24, 16);
    padding: 16px 0;
  }
  &__tbody {
    @extend %reset-table-tags;
    width: auto;
  }
  &__td {
    @extend %reset-table-tags, %table-cell;
    font-family: map-get($font-family, rubik);
    color: #777;
  }
  &__thead {
    @extend %reset-table-tags;
  }
  &__th {
    @extend %reset-table-tags, %reset-th, %table-cell;
    font-family: map-get($font-family, rubik);
    font-weight: 500;
    padding-right: 16px;
  }
  &__tr {
    @extend %reset-table-tags;
    #{$module}__thead & {
      border-bottom: 2px solid map-get($colour, blue);
    }
    #{$module}__tbody & {
      border-bottom: 1px solid #d4d4d4;
    }
  }
}