.#{$prefix}service-selector {
  @extend %reset-details;
  border-radius: 2px;
  &__link {
    @extend %reset-anchor;
    font-family: map-get($font-family, rubik);
    display: flex;
    color: map-get($colour, navy);
    border-radius: 4px;
    display: block;
    padding: 12px;
    font-size: rem(14);
    &:hover, &:focus {
      color: map-get($colour, blue);
      text-decoration: underline;
    }
    &--active:any-link {
      background: rgba(map-get($colour, selector-blue), .4);
      text-decoration: unset;
      color: map-get($colour, navy);
    }
    @include pseudoArrow(map-get($colour, navy));
    @include pseudoArrowDirection(right);
  }
  &__list {
    @extend %reset-list;
    padding: 16px;
    animation: fadeIn map-get($transition-duration, default);
    border: 1px solid rgba(map-get($colour, indigo), .4);
    border-radius: 0 0 2px 2px;
    background: map-get($colour, bg-blue);
  }
  &__title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    background: map-get($colour, indigo);
    color: #fff;
    font-family: map-get($font-family, rubik);
    font-size: rem(16);
    text-transform: uppercase;
    padding: 16px 24px;
    border-radius: inherit;
    [open] > & {
      border-radius: 2px 2px 0 0;
      @include pseudoArrowDirection(top);
    }
    @include pseudoArrow(currentColor, true) {
      order: 2;
      align-self: center;
      flex-basis: auto;
    }
    @include pseudoArrowDirection(bottom);
  }
}