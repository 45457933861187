@charset "UTF-8";
.vcz18_user-nav__detail--value::after {
  content: "\a";
  white-space: pre;
}

.vcz18_breadcrumb__link, .vcz18_user-nav__profile, .vcz18_user-nav__logout, a.vcz18_user-nav__button, a.vcz18_user-nav__menu-btn, .vcz18_service-selector__link, .vcz18_main-nav__link, .vcz18_icon-link, .vcz18_link-button, a.vcz18_icon-button, .vcz18_button, .vcz18_list__link, .vcz18_anchor, .vcz18_page-footer__text-link, .vcz18_page-footer__medium .vcz18_icon-button {
  color: inherit;
  text-decoration: inherit;
}
button.vcz18_user-nav__button, button.vcz18_user-nav__menu-btn, button.vcz18_icon-button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button;
  user-select: none;
}
button.vcz18_user-nav__button::-moz-focus-inner, button.vcz18_user-nav__menu-btn::-moz-focus-inner, button.vcz18_icon-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.vcz18_user-nav__details, .vcz18_user-nav__details dt, .vcz18_user-nav__details dd {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
.vcz18_service-selector {
  display: block;
}
.vcz18_service-selector > summary {
  list-style-type: none;
}
.vcz18_service-selector > summary::-webkit-details-marker {
  display: none;
}
.vcz18_service-selector:not([open]) > summary ~ * {
  display: none;
}
.vcz18_sector-select__field {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}
.vcz18_sector-select__field legend {
  padding: 0;
  display: table;
}
body:not(:-moz-handler-blocked) .vcz18_sector-select__field {
  display: table-cell;
}
.vcz18_page-header__quick-links, .vcz18_page-footer__social, .vcz18_page-footer__docs, .vcz18_breadcrumb__list, .vcz18_banner__links, .vcz18_service-selector__list, .vcz18_main-nav__list, .vcz18_list--arrow, .vcz18_list--check {
  list-style: none;
  margin: 0;
  padding: 0;
}
.vcz18_table {
  border-collapse: collapse;
  border-spacing: 0;
}
.vcz18_table__tr, .vcz18_table__th, .vcz18_table__thead, .vcz18_table__td, .vcz18_table__tbody, .vcz18_table {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
}
.vcz18_table__th {
  text-align: left;
}

.vcz18_user-nav__detail--head.vcz18_user-nav__detail--title, .vcz18_sector-select__check {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

@media (max-width: 611px) {
  .vcz18_non-mobile {
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes popUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-0.5px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}
@keyframes tableFlip {
  0% {
    transform: scaleY(0) rotateX(-90deg);
    opacity: 0;
    border-bottom-color: #fff;
  }
  100% {
    transform: scaleY(1) rotateX(0);
    opacity: 1;
    border-bottom-color: #d4d4d4;
  }
}
html {
  height: 100%;
  font-size: initial;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("/static/assets/fonts/montserrat.woff2") format("woff2"), url("/static/assets/fonts/montserrat.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("/static/assets/fonts/montserrat-medium.woff2") format("woff2"), url("/static/assets/fonts/montserrat-medium.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: local("Rubik"), local("Rubik-Regular"), url("/static/assets/fonts/rubik.woff2") format("woff2"), url("/static/assets/fonts/rubik.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.vcz18_heading--h2 {
  font-family: "Montserrat", Helvetica, sans-serif;
  font-weight: 500;
  margin: 0;
  font-size: 1.75rem;
}
.vcz18_heading--h3 {
  font-family: "Montserrat", Helvetica, sans-serif;
  font-weight: 500;
  margin: 0;
  font-size: 1.5rem;
}
.vcz18_heading--h4 {
  font-family: "Montserrat", Helvetica, sans-serif;
  font-weight: 500;
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.2;
}
.vcz18_heading--h5 {
  font-family: "Montserrat", Helvetica, sans-serif;
  font-weight: 500;
  margin: 0;
  font-style: italic;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}
main.vcz18_single-service, .vcz18_page-header__container, .vcz18_page-footer__top, .vcz18_page-footer__bottom, .vcz18_banner__contain, .vcz18_container {
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}
@media (max-width: 611px) {
  main.vcz18_single-service, .vcz18_page-header__container, .vcz18_page-footer__top, .vcz18_page-footer__bottom, .vcz18_banner__contain, .vcz18_container {
    max-width: 1208px;
    padding: 0 8px;
  }
}
@media (min-width: 612px) and (max-width: 1023px) {
  main.vcz18_single-service, .vcz18_page-header__container, .vcz18_page-footer__top, .vcz18_page-footer__bottom, .vcz18_banner__contain, .vcz18_container {
    max-width: 1208px;
    padding: 0 16px;
  }
}
@media (min-width: 1024px) and (max-width: 1431px) {
  main.vcz18_single-service, .vcz18_page-header__container, .vcz18_page-footer__top, .vcz18_page-footer__bottom, .vcz18_banner__contain, .vcz18_container {
    max-width: 1208px;
    padding: 0 32px;
  }
}
@media (min-width: 1432px) {
  main.vcz18_single-service, .vcz18_page-header__container, .vcz18_page-footer__top, .vcz18_page-footer__bottom, .vcz18_banner__contain, .vcz18_container {
    max-width: 1208px;
  }
}

main.vcz18_single-service, .vcz18_page-header__container, .vcz18_banner__contain, .vcz18_container, .vcz18_cols {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-flow: row dense;
}
@media (max-width: 611px) {
  main.vcz18_single-service, .vcz18_page-header__container, .vcz18_banner__contain, .vcz18_container, .vcz18_cols {
    grid-column-gap: 8px;
  }
}
@media (min-width: 612px) and (max-width: 1023px) {
  main.vcz18_single-service, .vcz18_page-header__container, .vcz18_banner__contain, .vcz18_container, .vcz18_cols {
    grid-column-gap: 16px;
  }
}
@media (min-width: 1024px) and (max-width: 1431px) {
  main.vcz18_single-service, .vcz18_page-header__container, .vcz18_banner__contain, .vcz18_container, .vcz18_cols {
    grid-column-gap: 32px;
  }
}
@media (min-width: 1432px) {
  main.vcz18_single-service, .vcz18_page-header__container, .vcz18_banner__contain, .vcz18_container, .vcz18_cols {
    grid-column-gap: 40px;
  }
}

.vcz18_anchor, .vcz18_page-footer__text-link, .vcz18_page-footer__medium .vcz18_icon-button, .vcz18_breadcrumb__link {
  font-family: "Rubik", Arial, sans-serif;
  color: #005cb9;
}
.vcz18_anchor:hover, .vcz18_page-footer__text-link:hover, .vcz18_page-footer__medium .vcz18_icon-button:hover, .vcz18_breadcrumb__link:hover, .vcz18_anchor:focus, .vcz18_page-footer__text-link:focus, .vcz18_page-footer__medium .vcz18_icon-button:focus, .vcz18_breadcrumb__link:focus {
  color: #3e8ede;
  text-decoration: underline;
}
.vcz18_anchor[href^="tel:"], [href^="tel:"].vcz18_page-footer__text-link, .vcz18_page-footer__medium [href^="tel:"].vcz18_icon-button, [href^="tel:"].vcz18_breadcrumb__link {
  white-space: nowrap;
}

.vcz18_body {
  height: 100%;
  min-height: 100%;
  min-width: 320px;
  display: grid;
  grid-template-rows: auto minmax(max-content, 1fr) auto;
  grid-template-columns: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}
.vcz18_body:not(.vcz18_body--tabbed) *:focus, .vcz18_body:not(.vcz18_body--tabbed) *:active {
  outline: 0;
}
.vcz18_body::before {
  content: "";
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.15s 0s, visibility 0s 0.15s;
}
.vcz18_body--locked {
  overflow: hidden;
  height: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}
.vcz18_body--locked::before {
  background-color: rgba(0, 0, 0, 0.2);
  visibility: visible;
  transition: background-color 0.15s, visibility 0s 0s;
  z-index: 9;
}
.vcz18_body--media::before {
  background-color: #fff;
  visibility: visible;
  transition: background-color 0.15s, visibility 0s 0s;
  z-index: 11;
  cursor: zoom-out;
}
.vcz18_body--message {
  grid-template-rows: min-content minmax(max-content, 1fr) auto;
}

.vcz18_heading--h2 {
  margin: 72px 0 32px;
}
.vcz18_heading--h2:first-child {
  margin-top: 0;
}
.vcz18_heading--h3 {
  margin: 72px 0 1em;
}
.vcz18_heading--h3:first-child {
  margin-top: 0;
}
.vcz18_heading--h4 {
  margin: 40px 0 16px;
}
.vcz18_heading--h4:first-child {
  margin-top: 0;
}
.vcz18_heading--h5 {
  margin: 40px 0 1em;
}
.vcz18_heading--h5:first-child {
  margin-top: 0;
}

.vcz18_list {
  font-family: "Rubik", Arial, sans-serif;
  padding: 0;
  grid-row-gap: 8px;
  margin-bottom: 32px;
}
.vcz18_list:last-child {
  margin-bottom: 0;
}
.vcz18_list--arrow {
  list-style-type: none;
}
.vcz18_list--check {
  list-style-type: none;
}
.vcz18_list--ol {
  margin-left: -16px;
  padding-left: 40px;
}
.vcz18_list--square {
  list-style-type: none;
  padding-left: 40px;
}
.vcz18_list--sub {
  list-style-type: none;
}
.vcz18_list--ul {
  list-style-type: none;
  padding-left: 40px;
}
.vcz18_list__item {
  position: relative;
  line-height: 1.5;
  padding: 0 16px;
}
.vcz18_list__item::before {
  position: absolute;
}
.vcz18_list--arrow .vcz18_list__item {
  padding-left: 56px;
}
.vcz18_list--arrow .vcz18_list__item::before {
  content: "";
  display: inline-block;
  border-width: 2px 2px 0.01px 0.01px;
  border-color: #005cb9 #005cb9 transparent transparent;
  border-style: solid;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  flex: 0 0 10px;
  margin-left: 2.0710678119px;
  margin-right: 16px;
  vertical-align: middle;
  left: 16px;
  top: 5px;
}
.vcz18_list--arrow .vcz18_list__item::before {
  transform: rotate(45deg) translate(-25%, 25%);
}
.vcz18_list--check .vcz18_list__item {
  padding-left: 56px;
}
.vcz18_list--check .vcz18_list__item::before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 8px;
  border-width: 0.01px 0.01px 2px 2px;
  border-color: transparent transparent #d18f00 #d18f00;
  border-style: solid;
  transform: rotate(-45deg);
  margin-right: 16px;
  box-sizing: border-box;
  vertical-align: top;
  transition: opacity 0.15s;
  left: 16px;
  top: 6px;
}
.vcz18_list--ol .vcz18_list__item {
  padding-left: 32px;
}
.vcz18_list--square .vcz18_list__item::before {
  content: "";
  background: currentColor;
  height: 4px;
  width: 4px;
  vertical-align: baseline;
  left: -24px;
  top: 0.5em;
}
.vcz18_list--sub .vcz18_list__item::before {
  content: "○";
  left: -24px;
}
.vcz18_list--ul .vcz18_list__item::before {
  content: "●";
  left: -24px;
  color: #777;
}
.vcz18_list--blue .vcz18_list__item::before {
  color: #3c79c3;
}
.vcz18_list__link {
  margin-bottom: 4px;
  color: #005cb9;
}
.vcz18_list__link:focus, .vcz18_list__link:hover {
  color: #3c79c3;
  text-decoration: underline;
}

.vcz18_par {
  margin: 24px 0;
  font-family: "Rubik", Arial, sans-serif;
  line-height: 1.5;
  color: #1f1309;
}
.vcz18_par:first-child, .vcz18_media + .vcz18_par {
  margin-top: 0;
}

.vcz18_strong {
  font-weight: 500;
}

.vcz18_table {
  width: 100%;
  margin-bottom: 32px;
}
.vcz18_table .vcz18_table__th, .vcz18_table .vcz18_table__td {
  line-height: 1.5;
  padding: 16px 0;
}
.vcz18_table__tbody {
  width: auto;
}
.vcz18_table__td {
  font-family: "Rubik", Arial, sans-serif;
  color: #777;
}
.vcz18_table__th {
  font-family: "Rubik", Arial, sans-serif;
  font-weight: 500;
  padding-right: 16px;
}
.vcz18_table__thead .vcz18_table__tr {
  border-bottom: 2px solid #3e8ede;
}
.vcz18_table__tbody .vcz18_table__tr {
  border-bottom: 1px solid #d4d4d4;
}

.vcz18_button {
  appearance: none;
  border: none;
  cursor: pointer;
  padding: 0.85em 1.28em;
  border-radius: 4px;
  transition: background-color 0.15s;
  display: inline-block;
  font-family: "Rubik", Arial, sans-serif;
}
.vcz18_button--normal {
  color: #005cb9;
  background-color: #fff;
}
.vcz18_button--normal:hover, .vcz18_button--normal:focus {
  background-color: #ebf4fc;
}
.vcz18_button--normal:active {
  background-color: #b1c9e7;
}
.vcz18_button--primary {
  color: #fff;
  background-color: #a26c00;
}
.vcz18_button--primary:hover, .vcz18_button--primary:focus {
  background-color: #d18f00;
}
.vcz18_button--secondary {
  color: #005cb9;
  background-color: #c2dbf4;
}
.vcz18_button--secondary:hover, .vcz18_button--secondary:focus {
  color: #fff;
  background-color: #3e8ede;
}
.vcz18_button--secondary:active {
  color: #fff;
  background-color: #005cb9;
}
.vcz18_button--secondary2 {
  color: #fff;
  background-color: #3e8ede;
}
.vcz18_button--secondary2 svg {
  fill: #fff;
}
.vcz18_button--secondary2:hover, .vcz18_button--secondary2:focus {
  background-color: #005cb9;
}
.vcz18_button--secondary2:active {
  background-color: #3e8ede;
}
.vcz18_button--outline--secondary2 {
  background-color: #fff;
  border: 1px solid #3e8ede;
  color: #3e8ede;
}
.vcz18_button--outline--secondary2 svg {
  fill: #3e8ede;
}
.vcz18_button--outline--secondary2:hover, .vcz18_button--outline--secondary2:focus {
  color: #fff;
  background-color: #3e8ede;
}
.vcz18_button--outline--secondary2:hover svg, .vcz18_button--outline--secondary2:focus svg {
  fill: #fff;
}
.vcz18_button--outline--secondary2:active {
  color: #3e8ede;
  background-color: #fff;
}
.vcz18_button--outline--secondary2:active svg {
  fill: #3e8ede;
}

.vcz18_cols > * {
  grid-column-end: span 12;
}
@media (min-width: 1024px) {
  .vcz18_cols--double.vcz18_cols > * {
    grid-column-end: span 6;
  }
}

@media (min-width: 1024px) {
  .vcz18_cols--triple.vcz18_cols > * {
    grid-column-end: span 6;
  }
}
@media (min-width: 1432px) {
  .vcz18_cols--triple.vcz18_cols > * {
    grid-column-end: span 4;
  }
}

@media (min-width: 612px) {
  .vcz18_cols--quadruple.vcz18_cols > * {
    grid-column-end: span 6;
  }
}
@media (min-width: 1024px) {
  .vcz18_cols--quadruple.vcz18_cols > * {
    grid-column-end: span 4;
  }
}
@media (min-width: 1432px) {
  .vcz18_cols--quadruple.vcz18_cols > * {
    grid-column-end: span 3;
  }
}

.vcz18_cols__img {
  margin-top: 24px;
  max-width: 100%;
}

.vcz18_bg {
  overflow: hidden;
}
.vcz18_bg--blue {
  background-color: #ebf4fc;
}
.vcz18_bg--grey {
  background-color: #f8f8f8;
}
.vcz18_bg--indigo {
  background-color: #f3f7fb;
}
.vcz18_bg--red {
  background-color: #c22c0d;
}
.vcz18_bg--orange {
  background-color: #eb9900;
}
.vcz18_bg--green {
  background-color: #869123;
}
.vcz18_container {
  grid-row-gap: 40px;
}
header.vcz18_container {
  grid-row-gap: 16px;
}

.vcz18_container:not(header) {
  padding-top: 56px;
  padding-bottom: 72px;
}
.vcz18_container.pb-0 {
  padding-bottom: 0;
}
div.vcz18_container:not(.vcz18_bg) + div.vcz18_container:not(.vcz18_bg) {
  padding-top: 0;
}

.vcz18_container > .vcz18_content {
  grid-column: 1/span 12;
}
@media (min-width: 1024px) {
  .vcz18_container > .vcz18_content--main {
    grid-column-end: span 10;
  }
}
@media (min-width: 1432px) {
  .vcz18_container > .vcz18_content--main {
    grid-column-end: span 8;
  }
  .vcz18_container > .vcz18_content--side {
    grid-column: 10/span 3;
  }
}
.vcz18_content[data-row-span="2"] {
  grid-row: 1/span 2;
}
@media (max-width: 1431px) {
  .vcz18_content[data-row-span="2"] {
    grid-row: 3/span 1;
  }
}
.vcz18_content[data-row-span="3"] {
  grid-row: 1/span 3;
}
@media (max-width: 1431px) {
  .vcz18_content[data-row-span="3"] {
    grid-row: 4/span 1;
  }
}
.vcz18_content[data-row-span="4"] {
  grid-row: 1/span 4;
}
@media (max-width: 1431px) {
  .vcz18_content[data-row-span="4"] {
    grid-row: 5/span 1;
  }
}
.vcz18_content[data-row-span="5"] {
  grid-row: 1/span 5;
}
@media (max-width: 1431px) {
  .vcz18_content[data-row-span="5"] {
    grid-row: 6/span 1;
  }
}
.vcz18_content[data-row-span="6"] {
  grid-row: 1/span 6;
}
@media (max-width: 1431px) {
  .vcz18_content[data-row-span="6"] {
    grid-row: 7/span 1;
  }
}
.vcz18_content[data-row-span="7"] {
  grid-row: 1/span 7;
}
@media (max-width: 1431px) {
  .vcz18_content[data-row-span="7"] {
    grid-row: 8/span 1;
  }
}
.vcz18_content[data-row-span="8"] {
  grid-row: 1/span 8;
}
@media (max-width: 1431px) {
  .vcz18_content[data-row-span="8"] {
    grid-row: 9/span 1;
  }
}
.vcz18_content[data-row-span="9"] {
  grid-row: 1/span 9;
}
@media (max-width: 1431px) {
  .vcz18_content[data-row-span="9"] {
    grid-row: 10/span 1;
  }
}
.vcz18_content[data-row-span="10"] {
  grid-row: 1/span 10;
}
@media (max-width: 1431px) {
  .vcz18_content[data-row-span="10"] {
    grid-row: 11/span 1;
  }
}
.vcz18_content[data-row-span="11"] {
  grid-row: 1/span 11;
}
@media (max-width: 1431px) {
  .vcz18_content[data-row-span="11"] {
    grid-row: 12/span 1;
  }
}
.vcz18_content--border {
  border-top: 2px solid #3c79c3;
  padding-top: 36px;
}

.vcz18_icon-button {
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
button.vcz18_icon-button {
  color: #777;
}
@media (min-width: 1024px) {
  button.vcz18_icon-button {
    color: #005cb9;
  }
}

a.vcz18_icon-button {
  color: #777;
}
@media (min-width: 1024px) {
  a.vcz18_icon-button {
    color: #005cb9;
  }
}

.vcz18_icon-button::before {
  content: "";
  z-index: 2;
  width: inherit;
  height: inherit;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  transform: scale(0);
  opacity: 0.1;
  background-color: currentColor;
  transition: transform 0.15s ease-in-out;
}
@media (min-width: 1024px) {
  .vcz18_icon-button::before {
    background-color: #ebf4fc;
    opacity: 1;
  }
}
.vcz18_icon-button > svg {
  z-index: 3;
  position: relative;
  display: inherit;
  width: inherit;
  height: inherit;
}
.vcz18_icon-button--clicked::before {
  transform: scale(1);
}
.vcz18_icon-button--updated {
  position: relative;
  overflow: visible;
}
.vcz18_icon-button--updated::after {
  content: "";
  animation: popUp 0.15s ease-in-out;
  z-index: 3;
  width: 10px;
  height: 10px;
  background: #b0381f;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: -5px;
}

.vcz18_icon-link, .vcz18_link-button {
  padding: 8px 16px;
  color: #005cb9;
  transition: background-color 0.15s;
  border-radius: 4px;
  display: block;
  line-height: 1.5;
  font-weight: 500;
  font-family: "Rubik", Arial, sans-serif;
  float: left;
  clear: both;
}
.vcz18_icon-link:hover, .vcz18_icon-link:focus, .vcz18_link-button:hover, .vcz18_link-button:focus {
  background-color: #ebf4fc;
}
.vcz18_icon-link:active, .vcz18_link-button:active {
  background-color: #b1c9e7;
}
.vcz18_icon-link .vcz18_icon-link__icon, .vcz18_icon-link--external.vcz18_link-button::before, .vcz18_icon-link[target=_blank].vcz18_link-button::before, .vcz18_icon-link .vcz18_link-button__icon, .vcz18_link-button--external.vcz18_link-button::before, .vcz18_link-button[target=_blank].vcz18_link-button::before {
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  margin: -8px 8px -8px -16px;
}
.vcz18_icon-link--external.vcz18_link-button, .vcz18_icon-link[target=_blank].vcz18_link-button, .vcz18_link-button--external.vcz18_link-button, .vcz18_link-button[target=_blank].vcz18_link-button {
  display: inline-flex;
  align-items: center;
}
.vcz18_icon-link--external.vcz18_link-button::before, .vcz18_icon-link[target=_blank].vcz18_link-button::before, .vcz18_link-button--external.vcz18_link-button::before, .vcz18_link-button[target=_blank].vcz18_link-button::before {
  content: url("./img/icon/external.svg");
  flex: 0 0 40px;
}

.vcz18_lamel {
  background-repeat: repeat-x;
  background-image: url("./img/lamel.svg");
  display: block;
  z-index: 2;
  height: 6px;
  background-position: calc(50% + 4.5px) 0;
  background-size: 405px 6px;
}
@media (min-width: 612px) {
  .vcz18_lamel {
    height: 7px;
    background-position: calc(50% + 5.25px) 0;
    background-size: 472.5px 7px;
  }
}
@media (min-width: 1024px) {
  .vcz18_lamel {
    height: 8px;
    background-position: calc(50% + 6px) 0;
    background-size: 540px 8px;
  }
}

.vcz18_logo {
  fill: #005cb9;
}
.vcz18_logo__icon--blue {
  fill: #3e8ede;
}
.vcz18_logo__icon--navy {
  fill: #005cb9;
}
.vcz18_logo__icon--oker {
  fill: #d18f00;
}

.vcz18_page-main {
  display: block;
}

.vcz18_sector-select {
  margin: 16px 0;
  color: #777;
  text-transform: uppercase;
}
.vcz18_sector-select__name {
  display: block;
  padding: 7px 16px;
  cursor: pointer;
  transition: background-color 0.15s;
}
.vcz18_sector-select__name:not(.vcz18_sector-select__name--selected):hover, .vcz18_sector-select__check:focus + .vcz18_sector-select__name:not(.vcz18_sector-select__name--selected) {
  background-color: #f5f5f5;
}
.vcz18_sector-select__name::before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 8px;
  border-width: 0.01px 0.01px 2px 2px;
  border-color: transparent transparent currentColor currentColor;
  border-style: solid;
  transform: rotate(-45deg);
  margin-right: 16px;
  box-sizing: border-box;
  vertical-align: top;
  transition: opacity 0.15s;
  opacity: 0;
}
:checked + .vcz18_sector-select__name {
  color: #000;
}
:checked + .vcz18_sector-select__name::before {
  opacity: 1;
}
.vcz18_sector-select__name--selected {
  background-color: #3c79c3;
}
.vcz18_sector-select__name--selected, :checked + .vcz18_sector-select__name--selected {
  color: #fff;
}
.vcz18_sector-select__title {
  font-size: 0.75rem;
}
.vcz18_sector-select .vcz18_sector-select__title {
  margin-bottom: 12px;
  padding: 0 16px;
}

.vcz18_sprites {
  display: none;
}

.vcz18_main-nav {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  z-index: 10;
  padding-left: 8px;
  margin-left: -8px;
}
@media (min-width: 612px) {
  .vcz18_main-nav {
    padding-left: 16px;
    margin-left: -16px;
  }
}
@media (min-width: 1024px) {
  .vcz18_main-nav {
    padding-left: 32px;
    margin-left: -32px;
  }
}
@media (min-width: 1432px) {
  .vcz18_main-nav {
    padding-left: 40px;
    margin-left: -40px;
  }
}
.vcz18_main-nav::before {
  content: "";
  left: 0;
  top: 0;
  height: 100vh;
  background: #fff;
  position: fixed;
  display: block;
  visibility: hidden;
  width: 280px;
  transform: translateX(-100%);
  transition: transform 0.15s ease-in-out, visibility 0s 0.15s;
}
.vcz18_main-nav--opened::before {
  visibility: visible;
  transform: translateX(0);
  transition: transform 0.15s ease-in-out;
}
.vcz18_main-nav__icon {
  position: relative;
}
@media (min-width: 1024px) {
  .vcz18_main-nav__icon {
    display: none;
  }
}
.vcz18_main-nav__icon-bar {
  width: 18px;
  height: 2px;
  background: currentColor;
  position: absolute;
  display: block;
  transition: transform 0.15s;
  z-index: 3;
}
.vcz18_main-nav__icon-bar:nth-of-type(1) {
  left: 9px;
  top: 13px;
  transform-origin: 94.4444444444% 50%;
}
.vcz18_main-nav__icon-bar:nth-of-type(2) {
  left: 11px;
  top: 19px;
  transform-origin: 50% 50%;
}
.vcz18_main-nav__icon-bar:nth-of-type(3) {
  left: 13px;
  top: 25px;
  transform-origin: 5.5555555556% 50%;
}
.vcz18_main-nav--opened .vcz18_main-nav__icon-bar:nth-of-type(1) {
  transform: rotate(-45deg) scaleX(1.03);
}
.vcz18_main-nav--opened .vcz18_main-nav__icon-bar:nth-of-type(2) {
  transform: rotate(45deg);
}
.vcz18_main-nav--opened .vcz18_main-nav__icon-bar:nth-of-type(3) {
  transform: rotate(-45deg) scaleX(0);
  opacity: 0;
}
.vcz18_main-nav__item {
  flex-shrink: 0;
}
@media (max-width: 1023px) {
  .vcz18_main-nav__item {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s 0s, visibility 0s 0.15s;
  }
  .vcz18_main-nav--opened .vcz18_main-nav__item {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.15s 0.15s;
  }
}
.vcz18_main-nav__link {
  display: flex;
  line-height: 88px;
  text-transform: uppercase;
  color: #005cb9;
}
.vcz18_main-nav__link:hover, .vcz18_main-nav__link:focus {
  color: #3e8ede;
}
.vcz18_main-nav__link--active:any-link {
  color: #005cb9;
}
@media (max-width: 1023px) {
  .vcz18_main-nav__link {
    line-height: 55px;
    border: 0;
    border-bottom: 1px solid #dedede;
  }
}
.vcz18_main-nav__link-title {
  font-family: "Montserrat", Helvetica, sans-serif;
  font-weight: 500;
  font-size: 0.9375rem;
  margin: 0;
  position: relative;
}
@media (min-width: 1024px) {
  .vcz18_main-nav__link-title {
    margin: 0 16px;
  }
}
.vcz18_main-nav__link-title::after {
  content: "";
  height: 2px;
  background: currentColor;
  position: absolute;
  bottom: -1px;
  left: 0;
  border-radius: 1px;
  transition: transform 0.15s ease-in-out;
  width: 100%;
  transform: scaleX(0);
  transform-origin: 0% 50%;
}
@media (min-width: 1024px) {
  .vcz18_main-nav__link-title::after {
    bottom: 8px;
  }
}
.vcz18_main-nav__link--active .vcz18_main-nav__link-title::after, .vcz18_main-nav__link:hover .vcz18_main-nav__link-title::after, .vcz18_main-nav__link:focus .vcz18_main-nav__link-title::after {
  transform: scale(1);
}
.vcz18_main-nav__list {
  display: flex;
  box-sizing: border-box;
  align-items: center;
}
@media (max-width: 611px) {
  .vcz18_main-nav__list {
    padding: 0 16px;
  }
}
@media (min-width: 612px) and (max-width: 1023px) {
  .vcz18_main-nav__list {
    padding: 0 32px;
  }
}
@media (max-width: 1023px) {
  .vcz18_main-nav__list {
    align-items: stretch;
    width: 280px;
    position: absolute;
    flex-direction: column;
    top: 100%;
    left: 0;
    visibility: hidden;
    transform: translateX(-100%);
    transition: transform 0.15s ease-in-out, visibility 0s 0.15s;
  }
  .vcz18_main-nav--opened .vcz18_main-nav__list {
    transform: translateX(0);
    visibility: visible;
    transition: transform 0.15s ease-in-out;
  }
}

.vcz18_service-selector {
  border-radius: 2px;
}
.vcz18_service-selector__link {
  font-family: "Rubik", Arial, sans-serif;
  display: flex;
  color: #005cb9;
  border-radius: 4px;
  display: block;
  padding: 12px;
  font-size: 0.875rem;
}
.vcz18_service-selector__link:hover, .vcz18_service-selector__link:focus {
  color: #3e8ede;
  text-decoration: underline;
}
.vcz18_service-selector__link--active:any-link {
  background: rgba(194, 219, 244, 0.4);
  text-decoration: unset;
  color: #005cb9;
}
.vcz18_service-selector__link::before {
  content: "";
  display: inline-block;
  border-width: 2px 2px 0.01px 0.01px;
  border-color: #005cb9 #005cb9 transparent transparent;
  border-style: solid;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  flex: 0 0 10px;
  margin-left: 2.0710678119px;
  margin-right: 16px;
  vertical-align: middle;
}
.vcz18_service-selector__link::before {
  transform: rotate(45deg) translate(-25%, 25%);
}
.vcz18_service-selector__list {
  padding: 16px;
  animation: fadeIn 0.15s;
  border: 1px solid rgba(60, 121, 195, 0.4);
  border-radius: 0 0 2px 2px;
  background: #f5f8fc;
}
.vcz18_service-selector__title {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background: #3c79c3;
  color: #fff;
  font-family: "Rubik", Arial, sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 16px 24px;
  border-radius: inherit;
}
[open] > .vcz18_service-selector__title {
  border-radius: 2px 2px 0 0;
}
[open] > .vcz18_service-selector__title::before {
  transform: rotate(315deg) translate(-25%, 25%);
}
.vcz18_service-selector__title::before {
  content: "";
  display: inline-block;
  border-width: 2px 2px 0.01px 0.01px;
  border-color: currentColor currentColor transparent transparent;
  border-style: solid;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  flex: 0 0 10px;
  margin-left: 2.0710678119px;
  margin-right: 16px;
  vertical-align: middle;
  transition: margin-left 0.15s, transform 0.15s;
  order: 2;
  align-self: center;
  flex-basis: auto;
}
.vcz18_service-selector__title::before {
  transform: rotate(135deg) translate(-25%, 25%);
}

.vcz18_user-nav {
  position: relative;
  font-family: "Rubik", Arial, sans-serif;
}
@media (max-width: 1023px) {
  .vcz18_user-nav {
    display: none;
  }
}
@media (min-width: 1024px) and (max-width: 1431px) {
  .vcz18_user-nav {
    margin-left: 32px;
  }
}
@media (min-width: 1432px) {
  .vcz18_user-nav {
    margin-left: 40px;
  }
}
.vcz18_user-nav__menu-btn {
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.15s;
}
.vcz18_user-nav .vcz18_user-nav__menu-btn {
  background-color: #f5f8fc;
  border: 0;
  color: #000;
  margin: 0;
  text-align: left;
  border-radius: 0;
  padding: 8px 16px;
  text-transform: none;
  font-size: 14px;
}
.vcz18_user-nav__menu-btn:first-of-type {
  margin-top: 16px;
}
a.vcz18_user-nav__menu-btn {
  display: block;
}

.vcz18_user-nav__menu-btn:hover, .vcz18_user-nav__menu-btn:focus {
  background-color: #ebf4fc;
  color: #005cb9;
}
.vcz18_user-nav__button {
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.15s;
}
.vcz18_user-nav .vcz18_user-nav__button {
  background-color: #f5f8fc;
  border: 1px solid #b1c9e7;
  color: #005cb9;
}
a.vcz18_user-nav__button {
  display: block;
}

.vcz18_user-nav__button:hover, .vcz18_user-nav__button:focus {
  background-color: #ebf4fc;
}
.vcz18_user-nav__launch {
  text-align: left;
  align-items: center;
  display: inline-flex;
}
.vcz18_user-nav--opened .vcz18_user-nav__launch {
  background-color: #ebf4fc;
}
.vcz18_user-nav__detail {
  color: #777;
  display: inline;
}
.vcz18_user-nav .vcz18_user-nav__detail {
  line-height: 1.5714285714;
}
.vcz18_user-nav__detail--head {
  color: #000;
  text-transform: uppercase;
}
.vcz18_user-nav__detail--title::after {
  content: ": ";
}
.vcz18_user-nav__details {
  padding: 16px;
  border-bottom: 1px solid #99c3ed;
}
.vcz18_user-nav__dots {
  height: 40px;
  width: 40px;
}
.vcz18_user-nav__logout {
  border: none;
  border-top: 1px solid #99c3ed;
  padding: 16px 18px;
  margin-top: 16px;
  text-align: left;
  display: block;
  color: #000;
  text-transform: none;
  transition: background-color 0.15s;
}
.vcz18_user-nav__logout:hover, .vcz18_user-nav__logout:focus {
  background-color: #ebf4fc;
  color: #005cb9;
}
.vcz18_user-nav__popup {
  z-index: 7;
  position: absolute;
  top: calc(100% - 2px);
  right: 0;
  width: 296px;
  background-color: #f5f9fc;
  border: 1px solid #99c3ed;
  border-radius: 2px;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.9);
  transform-origin: 100% 0%;
  transition: opacity 0.15s, transform 0.15s, visibility 0s 0.15s;
}
.vcz18_user-nav--opened .vcz18_user-nav__popup {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.15s, transform 0.15s;
}
.vcz18_user-nav__profile {
  margin: 16px;
  text-align: center;
  padding: 8px;
  font-size: 0.75rem;
  text-transform: uppercase;
}
.vcz18_user-nav__user {
  margin: 12px;
  display: inline-flex;
  flex-direction: column;
  white-space: nowrap;
}
.vcz18_user-nav__user--name {
  color: #005cb9;
  margin-bottom: 4px;
  overflow: hidden;
  display: block;
}
.vcz18_user-nav__user--id {
  color: #333;
  font-size: 0.75rem;
}

.vcz18_banner {
  background: #3e8ede;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: -8px;
  margin-right: -8px;
}
.vcz18_banner:first-child {
  margin-top: 24px;
}
@media (min-width: 1024px) {
  .vcz18_banner {
    border-radius: 8px;
  }
}
@media (min-width: 612px) {
  .vcz18_banner {
    padding-left: 16px;
    padding-right: 16px;
    margin-left: -16px;
    margin-right: -16px;
  }
}
@media (min-width: 1024px) {
  .vcz18_banner {
    padding-left: 32px;
    padding-right: 32px;
    margin-left: -32px;
    margin-right: -32px;
  }
}
@media (min-width: 1432px) {
  .vcz18_banner {
    padding-left: 112px;
    padding-right: 112px;
    margin-left: -112px;
    margin-right: -112px;
  }
}
@media (min-width: 612px) {
  .vcz18_banner__contain {
    padding-left: 0;
    padding-right: 0;
  }
}
.vcz18_banner__content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 32px;
  padding-bottom: 32px;
  color: #fff;
}
@media (min-width: 612px) {
  .vcz18_banner__content {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
@media (min-width: 1024px) {
  .vcz18_banner__content {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .vcz18_banner--medium .vcz18_banner__content {
    min-height: 168px;
  }
  .vcz18_banner--extended .vcz18_banner__content, .vcz18_banner--search .vcz18_banner__content {
    min-height: 224px;
  }
  .vcz18_banner--extended.small .vcz18_banner__content {
    min-height: 156px;
  }
}
.vcz18_banner__content .vcz18_rich-text p:not([class*=vcz18_]) {
  color: inherit;
  font-size: 1.125rem;
  line-height: 1.5555555556;
}
.vcz18_banner__content > :last-child {
  margin-bottom: 0;
}
.vcz18_banner--basic .vcz18_banner__content {
  grid-column: 1/span 12;
}
@media (min-width: 612px) {
  .vcz18_banner--basic .vcz18_banner__content {
    grid-column: 1/span 8;
  }
}
.vcz18_banner--medium .vcz18_banner__content {
  grid-column: 1/span 12;
}
@media (min-width: 612px) {
  .vcz18_banner--medium .vcz18_banner__content {
    grid-column: 1/span 10;
  }
}
@media (min-width: 1024px) {
  .vcz18_banner--medium .vcz18_banner__content {
    grid-column: 1/span 7;
  }
}
@media (min-width: 1432px) {
  .vcz18_banner--medium .vcz18_banner__content {
    grid-column: 1/span 8;
  }
}
.vcz18_banner--extended .vcz18_banner__content {
  grid-column: 1/span 12;
}
@media (min-width: 612px) {
  .vcz18_banner--extended .vcz18_banner__content {
    grid-column: 1/span 10;
  }
}
@media (min-width: 1024px) {
  .vcz18_banner--extended .vcz18_banner__content {
    grid-column: 1/span 6;
  }
}
@media (min-width: 1432px) {
  .vcz18_banner--extended .vcz18_banner__content {
    grid-column: 1/span 3;
  }
}
.vcz18_banner--search .vcz18_banner__content {
  grid-column: 1/span 12;
}
@media (min-width: 1024px) {
  .vcz18_banner--search .vcz18_banner__content {
    grid-column: 1/span 7;
  }
}
.vcz18_banner__circle {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  max-height: 100%;
  margin: 0;
  display: block;
  fill: #3e8ede;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 1px;
  width: calc(100% + 8px);
  margin-right: -8px;
}
@media (min-width: 1024px) {
  .vcz18_banner__circle {
    border-radius: 0 8px 8px 0;
  }
}
@media (min-width: 612px) {
  .vcz18_banner__circle {
    width: calc(100% + 16px);
    margin-right: -16px;
  }
}
@media (min-width: 1024px) {
  .vcz18_banner__circle {
    width: calc(100% + 32px);
    margin-right: -32px;
  }
}
@media (min-width: 1432px) {
  .vcz18_banner__circle {
    width: calc(100% + 112px);
    margin-right: -112px;
  }
}
.vcz18_banner__image {
  position: relative;
}
@media (max-width: 1023px) {
  .vcz18_banner--medium .vcz18_banner__image {
    display: none;
  }
}
@media (min-width: 1024px) {
  .vcz18_banner--medium .vcz18_banner__image {
    grid-column: 9/span 4;
  }
}
@media (min-width: 1432px) {
  .vcz18_banner--medium .vcz18_banner__image {
    grid-column: 10/span 3;
  }
}
.vcz18_banner--extended .vcz18_banner__image {
  grid-column: 7/span 6;
}
@media (max-width: 1023px) {
  .vcz18_banner--extended .vcz18_banner__image {
    display: none;
  }
}
@media (min-width: 1432px) {
  .vcz18_banner--extended .vcz18_banner__image {
    grid-column: 5/span 8;
  }
}
.vcz18_banner--search .vcz18_banner__image {
  grid-column: 8/span 5;
}
@media (max-width: 1023px) {
  .vcz18_banner--search .vcz18_banner__image {
    display: none;
  }
}
.vcz18_banner__link {
  border-radius: 8px;
  color: inherit;
  font-weight: 400;
  align-self: flex-start;
}
.vcz18_banner__link:not(:first-child) {
  margin-top: 32px;
}
.vcz18_banner__link:hover, .vcz18_banner__link:focus {
  background-color: rgba(0, 92, 185, 0.32);
}
.vcz18_banner__links:not(:first-child) {
  margin-top: 32px;
}
.vcz18_banner__link-item {
  display: inline-block;
}
.vcz18_banner__link-item:not(:last-child) {
  margin-right: 18px;
}
.vcz18_banner__subtitle {
  font-family: "Rubik", Arial, sans-serif;
  font-weight: 500;
  margin: 0 0 16px;
  order: -1;
  font-size: 1.125rem;
  font-weight: 400;
}
.vcz18_banner__title + .vcz18_banner__subtitle:last-child {
  margin-bottom: 16px;
}
.vcz18_banner__title {
  font-family: "Montserrat", Helvetica, sans-serif;
  font-weight: 500;
  margin: 0 0 24px;
  font-size: 1.5rem;
}
@media (min-width: 612px) {
  .vcz18_banner__title {
    font-size: 1.75rem;
  }
}
@media (min-width: 1024px) {
  .vcz18_banner__title {
    font-size: 2rem;
  }
}
@media (min-width: 1432px) {
  .vcz18_banner__title {
    font-size: 2.25rem;
  }
}

.vcz18_breadcrumb__item {
  display: inline;
  color: #777;
}
.vcz18_breadcrumb__item:first-child::before {
  display: none;
}
.vcz18_breadcrumb__item::before {
  content: ">";
  display: inline-block;
  transform: scaleX(0.8);
  margin: 0 8px;
}
.vcz18_breadcrumb__link {
  font-family: "Rubik", Arial, sans-serif;
  font-size: 0.875rem;
  text-decoration: underline;
}
.vcz18_breadcrumb__list {
  padding: 20px 0;
}

.vcz18_page-footer {
  font-size: 0.875rem;
}
.vcz18_page-footer__bottom {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
@media (max-width: 611px) {
  .vcz18_page-footer__bottom {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.vcz18_page-footer__certifications {
  padding: 0;
  display: flex;
  flex-direction: row;
}
.vcz18_page-footer__certifications li {
  display: inline;
  list-style-type: none;
  margin-left: 32px;
}
.vcz18_page-footer__certifications li:first-of-type {
  margin-left: 0;
}
.vcz18_page-footer__certification {
  flex-shrink: 0;
  margin: 32px 0;
}
@media (max-width: 611px) {
  .vcz18_page-footer__certification {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.vcz18_page-footer__certification--image {
  height: 75px;
}
.vcz18_page-footer__contact {
  flex-shrink: 0;
  font-weight: 400;
}
@media (max-width: 611px) {
  .vcz18_page-footer__contact {
    margin-top: 26px;
  }
}
.vcz18_page-footer__doc {
  margin: 0 12px;
}
@media (min-width: 612px) {
  .vcz18_page-footer__doc {
    margin: 0 22px 0 0;
  }
  .vcz18_page-footer__doc:last-child {
    margin: 0;
  }
}
.vcz18_page-footer__docs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 16px 16px 16px 0;
}
@media (max-width: 611px) {
  .vcz18_page-footer__docs {
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 0;
    margin-bottom: 0;
  }
}
.vcz18_page-footer__medium .vcz18_icon-button {
  transition: color 0.15s;
}
.vcz18_page-footer__social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  margin: 16px 0;
}
.vcz18_page-footer__text-link {
  font-family: "Rubik", Arial, sans-serif;
  line-height: 2.2857142857;
}
.vcz18_page-footer__top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
}
@media (min-width: 612px) {
  .vcz18_page-footer__top {
    justify-content: space-between;
  }
}

.vcz18_page-header {
  transition: box-shadow 0.5s;
}
.vcz18_body--message .vcz18_page-header-closed {
  box-shadow: 0 -32px 32px -32px rgba(0, 0, 0, 0.5);
}
.vcz18_page-header__homelink {
  margin: auto 0;
}
.vcz18_page-header__logo {
  display: block;
  height: 18px;
  width: 144px;
}
@media (min-width: 612px) {
  .vcz18_page-header__logo {
    height: 22px;
    width: 176px;
  }
}
@media (min-width: 1432px) {
  .vcz18_page-header__logo {
    height: 24px;
    width: 192px;
  }
}
.vcz18_page-header__nav {
  grid-column: 1/span 3;
}
@media (min-width: 612px) {
  .vcz18_page-header__nav {
    grid-column: 1/span 1;
  }
}
@media (min-width: 1024px) {
  .vcz18_page-header__nav {
    grid-column: 4/span 4;
  }
}
.vcz18_page-header__quick-links {
  display: flex;
  align-items: center;
}
.vcz18_page-header__quick-link {
  flex-shrink: 0;
  font-size: 0.875rem;
  margin: 7px 0 7px 12px;
}
.vcz18_page-header__quick-link:first {
  margin-left: 0;
}
.vcz18_page-header__side {
  font-weight: 400;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-column: 10/span 3;
}
@media (min-width: 612px) {
  .vcz18_page-header__side {
    grid-column: 6/span 7;
  }
}
@media (min-width: 1024px) {
  .vcz18_page-header__side {
    grid-column: 8/span 5;
  }
}
.vcz18_page-header__title {
  display: flex;
  align-items: center;
  grid-column: 4/span 6;
  justify-content: center;
}
@media (min-width: 612px) {
  .vcz18_page-header__title {
    grid-column: 2/span 4;
    justify-content: flex-start;
  }
}
@media (min-width: 1024px) {
  .vcz18_page-header__title {
    grid-column: 1/span 3;
  }
}

main.vcz18_single-service {
  grid-auto-rows: min-content;
}

.vcz18_single-service__app {
  grid-column: 2/span 10;
  margin-bottom: 32px;
}
.vcz18_single-service__header {
  grid-column: 2/span 10;
  margin-bottom: 24px;
}