@mixin of-type($tags) {
  @each $tag in $tags {
    @at-root #{$tag}#{&} {
      @content;
    }
  }
}
@mixin tagSelect($tags) {
  @each $tag in $tags {
    #{$tag}:not([class*='#{$prefix}']) {
      @content;
    }
  }
}