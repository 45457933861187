.#{$prefix}sector-select {
  $module: &;
  margin: 16px 0;
  color: #777;
  text-transform: uppercase;
  &__check {
    @extend %visually-hidden;
  }
  &__field {
    @extend %reset-fieldset;
  }
  &__name {
    $label: &;
    display: block;
    padding: 7px 16px;
    cursor: pointer;
    transition: background-color map-get($transition-duration, default);
    &:not(#{$label}--selected) {
      &:hover, #{$module}__check:focus + & {
        background-color: #f5f5f5;
      }
    }
    @include pseudoCheck {
      opacity: 0;
    }
    :checked + & {
      color: #000;
      &::before {
        opacity: 1;
      }
    }
    &--selected {
      &, :checked + & {
        color: #fff;
      }
      background-color: map-get($colour, indigo);
    }
  }
  &__title {
    font-size: rem(12);
    #{$module} & {
      margin-bottom: 12px;
      padding: 0 16px;
    }
  }
}