.#{$prefix}icon-button {
  $module: &;
  $size: 40px;
  width: $size;
  height: $size;
  display: block;
  position: relative;
  z-index: 1;
  cursor: pointer;
  @mixin overwriteColour {
    color: #777;
    @include bp-up(md) {
      color: map-get($colour, navy);
    }
  }
  @include of-type(button) {
    @extend %reset-button;
    @include overwriteColour;
  }
  @include of-type(a) {
    @extend %reset-anchor;
    @include overwriteColour;
  }
  &::before {
    content: '';
    z-index: 2;
    width: inherit;
    height: inherit;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    transform: scale(0);
    opacity: 0.1;
    background-color: currentColor;
    @include bp-up(md) {
      background-color: map-get($colour, light-blue);
      opacity: 1;
    }
    transition: transform map-get($transition-duration, default) ease-in-out;
  }
  > svg {
    z-index: 3;
    position: relative;
    display: inherit;
    width: inherit;
    height: inherit;
  }
  &--clicked::before {
    transform: scale(1);
  }
  &--updated {
    position: relative;
    overflow: visible;
    &::after {
      content: '';
      animation: popUp map-get($transition-duration, default) ease-in-out;
      z-index: 3;
      $size: 10px;
      width: $size;
      height: $size;
      background: map-get($colour, red);
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: -$size*0.5;
    }
  }
}