@use "sass:math";

.#{$prefix}page-footer {
  font-size: rem(14);
  &__bottom {
    @extend %container;
    display: flex;
    justify-content: space-between;
    align-content: center;
    @include bp-only(xs) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__certifications {
    padding: 0;
    display: flex;
    flex-direction: row;
    
    li {
      display: inline;
      list-style-type: none;
      margin-left: 32px;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  &__certification {
    flex-shrink: 0;
    margin: 32px 0;
    @include bp-only(xs) {
      margin-left: 20px;
      margin-right: 20px;
    }
    &--image {
      height: 75px;
    }
  }
  &__contact {
    flex-shrink: 0;
    font-weight: 400;
    @include bp-only(xs) {
      margin-top: 26px;
    }
  }
  &__doc {
    margin: 0 12px;
    @include bp-up(sm) {
      margin: 0 22px 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }
  &__docs {
    @extend %reset-list;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 16px 16px 16px 0;
    @include bp-only(xs) {
      flex-wrap: wrap;
      justify-content: center;
      margin-right: 0;
      margin-bottom: 0;
    }
  }
  &__medium {
    .#{$prefix}icon-button {
      @extend %default-a;
      transition: color map-get($transition-duration, default);
    }
  }
  &__social {
    @extend %reset-list;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    margin: 16px 0;
  }
  &__text-link {
    @extend %default-a, %reset-anchor;
    font-family: map-get($font-family, rubik);
    line-height: math.div(32, 14);
  }
  &__top {
    @extend %container;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    @include bp-up(sm) {
      justify-content: space-between;
    }
  }
}